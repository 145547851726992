import React, { useRef, useState } from "react";
import { Props } from "./types";
import { strToTuple } from "./util";

function clearOldKeys() {
  const KEY = "anumati.check.tuples";
  sessionStorage.removeItem(KEY);
}

function getSessionValue(api: string): string {
  const LOCAL_STORAGE_KEY = `${api}-tuples`;
  return sessionStorage.getItem(LOCAL_STORAGE_KEY) || "";
}
function setSessionValue(api: string, text: string) {
  const LOCAL_STORAGE_KEY = `${api}-tuples`;
  sessionStorage.setItem(LOCAL_STORAGE_KEY, text);
}

function ApiTabPanel({ model }: Props) {
  clearOldKeys();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [apiResponse, setApiResponse] = useState<any>({});
  const [timeTakenInLastCheck, setTimeTakenInLastCheck] = useState(0);

  const apis = ["check", "read", "expand"];
  const [selectedApi, setSelectedApi] = useState("check");

  const textFromSessionStorage = getSessionValue(selectedApi);
  const [text, setText] = useState(textFromSessionStorage);

  const onSubmit = () => {
    const start = new Date();

    if (text) {
      setLoading(true);
      //save
      setSessionValue(selectedApi, text);

      const preparePayload = (text: string, api: string) => {
        const tuples = text
          .trim()
          .split("\n")
          .map((t: string) => t.trim())
          .filter((t: string) => t.length > 0)
          .map(strToTuple)
          .map((tup: any) => ({
            objectNamespace: tup.object_namespace,
            objectId: tup.object_id,
            relation: tup.relation,
            usersetNamespace: tup.userset_namespace,
            usersetId: tup.userset_id,
          }));

        let payload: any = {};
        if (selectedApi === "read") {
          payload.tuple_keys = tuples;
        } else if (selectedApi === "check") {
          payload.tuples = tuples;
        }
        return payload;
      };

      fetch(`/api/models/${model.id}/${selectedApi}`, {
        method: "POST",
        body: JSON.stringify(preparePayload(text, selectedApi)),
      })
        .then((res) => {
          if (res.status !== 200) {
            setError(true);
            throw new Error("Check failed " + res.statusText);
          }
          return res.json();
        })
        .then((resp) => {
          setError(false);
          setApiResponse((apiResponse: any) => {
            const newResp = { ...apiResponse };
            newResp[selectedApi] = resp;
            return newResp;
          });
        })
        .finally(() => {
          setLoading(false);

          const end = new Date();
          setTimeTakenInLastCheck(end.getTime() - start.getTime());
        });
    }
  };

  const onSelectApi = (event: any) => {
    const newApiValue = event.target.value;
    setSelectedApi(newApiValue);
    const text = getSessionValue(newApiValue);
    setText(text);
  };

  let placeholder;
  switch (selectedApi) {
    case "check":
      placeholder = "video:5676#can_view@user:78756";
      break;
    default:
      placeholder =
        "video:5676#can_view or #can_view@user:78756 or video:5676#";
      break;
  }

  return (
    <>
      <br />
      <select
        value={selectedApi}
        onChange={onSelectApi}
        style={{ width: "70px", height: "30px" }}
      >
        {apis.map((api, i) => {
          return (
            <option key={`API-${i}`} value={api}>
              {api}
            </option>
          );
        })}
      </select>
      <div className="App-check-editor">
        <textarea
          className="App-check-text-area"
          value={text}
          spellCheck="false"
          onChange={(e) => setText(e.target.value)}
          placeholder={placeholder}
        />
        <div>
          <button className="App-data-save-btn" onClick={onSubmit}>
            {loading && <span>Executing...</span>}
            {!loading && <span>Execute</span>}
          </button>
        </div>
        <div className="App-check-results">
          {false && timeTakenInLastCheck > 0 && (
            <div
              className="App-check-result-item"
              style={{ fontWeight: "bold" }}
            >
              It took {timeTakenInLastCheck} ms
            </div>
          )}
          {error && (
            <div
              className="App-check-result-item"
              style={{ fontWeight: "bold" }}
            >
              !!! "{selectedApi}" api failed. !!!
            </div>
          )}
          <ApiResponseComponent api={selectedApi} response={apiResponse} />
        </div>
      </div>
    </>
  );
}
function ApiResponseComponent(props: any) {
  const { response, api } = props;
  if (!response[api]) return <div />;

  if (api === "check") return <CheckApiResponse response={response[api]} />;
  if (api === "read") return <ReadApiResponse response={response[api]} />;
  return <div />;
}

function CheckApiResponse(props: any) {
  const resp = props.response.responses as Record<string, string>;
  return (
    <>
      {Object.entries(resp).map((r, i) => (
        <div className="App-check-result-item" key={`IDEX-${i}`}>
          {r[0]} --{" "}
          <span
            style={
              r[1]
                ? { color: "green", fontSize: "16px", fontWeight: "bold" }
                : { color: "red", fontSize: "16 px", fontWeight: "bold" }
            }
          >
            {"" + r[1]}
          </span>
        </div>
      ))}
    </>
  );
}

function ReadApiResponse(props: any) {
  const tuples = props.response;
  if (tuples && tuples.length > 0 && tuples[0].objectNamespace) {
    return (
      <table style={{ border: "1px solid black" }}>
        <tr>
          <th>Object</th>
          <th>Relation</th>
          <th>Usersets</th>
        </tr>
        {tuples.map((r: any) => (
          <>
            <tr>
              <td rowSpan={r.usersets.length}>
                {r.objectNamespace}:{r.objectId}
              </td>
              <td rowSpan={r.usersets.length}>{r.relation}</td>
              <td>
                {r.usersets[0].usersetNamespace}:{r.usersets[0].usersetId}
              </td>
            </tr>
            {r.usersets.slice(1).map((u: any) => (
              <tr>
                <td>
                  {u.usersetNamespace}:{u.usersetId}
                </td>
              </tr>
            ))}
          </>
        ))}
      </table>
    );
  }

  return (
    <table style={{ border: "1px solid black" }}>
      <tr>
        <th>Objects</th>
        <th>Relation</th>
        <th>Userset</th>
      </tr>
      {tuples.map((r: any) => (
        <>
          <tr>
            <td>
              {r.objects[0].objectNamespace}:{r.objects[0].objectId}
            </td>
            <td rowSpan={r.objects.length}>{r.relation}</td>
            <td rowSpan={r.objects.length}>
              {r.usersetNamespace}:{r.usersetId}
            </td>
          </tr>
          {r.objects.slice(1).map((u: any) => (
            <tr>
              <td>
                {u.objectNamespace}:{u.objectId}
              </td>
            </tr>
          ))}
        </>
      ))}
    </table>
  );
}
export default ApiTabPanel;
