import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import Editor, { Monaco } from "@monaco-editor/react";
import * as monaco from "monaco-editor";
import TupleEditor from "./TupleEditor";
import { Model, Store } from "./types";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import ApiTabPanel from "./ApiTabPanel";

function App() {
  const editorRef = useRef(null);

  const [newStoreName, setNewStoreName] = useState<string | null>(null);
  const [stores, setStores] = useState<Store[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [model, setModel] = useState<Model>();

  useEffect(() => {
    fetch("/api/stores")
      .then((res) => res.json())
      .then((data) => {
        if (data.length === 0) {
          return;
        }
        setStores([...stores, ...data]);
        setSelectedIndex(0);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedIndex === -1) {
      return;
    }
    const store = stores[selectedIndex];
    fetch(`/api/stores/${store.id}`)
      .then((res) => {
        return res.json();
      })
      .then((resp) => {
        const store = resp as Store;
        if (!store) {
          return;
        }
        setModel(store.model);
      });
  }, [selectedIndex, stores]);

  const onSelect = (event: any) => {
    setSelectedIndex(parseInt(event.target.value));
  };

  function onSave() {
    if (selectedIndex === -1) {
      return;
    }

    const isDirty =
      //@ts-ignore
      editorRef.current?.getValue() !== (model && model.config);
    if (!isDirty) {
      return;
    }

    setLoading(true);

    if (!model) {
      fetch(`/api/stores/${stores[selectedIndex].id}/models`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          //@ts-ignore
          config: editorRef.current?.getValue(),
        }),
      }).then((res) => {
        setLoading(false);
        return res.json();
      });
    } else {
      fetch(`/api/models/${model.id}`, {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          //@ts-ignore
          config: editorRef.current?.getValue(),
        }),
      }).then((res) => {
        setLoading(false);
        return res.json();
      });
    }
  }

  function handleEditorChange(
    value: string | undefined,
    ev: monaco.editor.IModelContentChangedEvent
  ) {}

  function handleEditorDidMount(
    editor: monaco.editor.IStandaloneCodeEditor,
    monaco: Monaco
  ) {
    //@ts-ignore
    editorRef.current = editor;
  }

  function handleEditorWillMount(monaco: Monaco) {}

  function handleEditorValidation(markers: any[]) {
    // model markers
    // markers.forEach(marker => console.log('onValidate:', marker.message));
  }

  // const selected = stores.length > 0 ? stores[selectedIndex].name : "";

  function onCreateClick() {
    if (newStoreName) {
      fetch("/api/stores", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({ name: newStoreName }),
      });
      setNewStoreName("");
    }
    closeModal();
  }

  const closeModal = () => {
    const modal = document.getElementById("myModal");
    if (modal) {
      modal.style.display = "none";
    }
  };

  const openModal = () => {
    const modal = document.getElementById("myModal");
    if (modal) {
      modal.style.display = "block";
    }
  };

  return (
    <div>
      <header>
        <h1 id="App-title">Anumati playground</h1>
        <div id="myModal" className="modal">
          <div className="modal-content">
            <div className="model-header-container">
              <h2 className="model-header">Create new store</h2>
              <span onClick={closeModal} className="close">
                &times;
              </span>
            </div>
            <div className="model-body-container">
              <label>New store name:</label>
              <input
                className="Create-store-input"
                type="text"
                value={newStoreName || ""}
                onChange={(e) => setNewStoreName(e.target.value)}
              />
            </div>
            <div className="model-footer-container">
              <button className="App-data-cancel-btn" onClick={closeModal}>
                Cancel
              </button>
              <button className="App-data-save-btn" onClick={onCreateClick}>
                Save
              </button>
            </div>
          </div>
        </div>
        <div className="Store-dropdown-container">
          {stores.length > 0 && (
            <select
              className="Store-dropdown"
              value={selectedIndex}
              onChange={onSelect}
            >
              {stores.map((s, index) => (
                <option key={s.name} value={index}>
                  {s.name}
                </option>
              ))}
            </select>
          )}
          <button className="Create-store-button" onClick={openModal}>
            Create new store
          </button>
        </div>
      </header>
      <div className="App-main">
        <div>
          <div className="App-ns-selector">
            <button
              className="App-save-btn"
              onClick={onSave}
              disabled={loading}
            >
              {loading && <span>Saving model...</span>}
              {!loading && <span>Save model</span>}
            </button>
          </div>
          <div className="App-ns-editor">
            <Editor
              height="90vh"
              defaultLanguage="yaml"
              // defaultValue="# namespace"
              onChange={handleEditorChange}
              onMount={handleEditorDidMount}
              beforeMount={handleEditorWillMount}
              onValidate={handleEditorValidation}
              value={model && model.config}
              options={{
                minimap: { enabled: false },
                // scrollBeyondLastLine: false,
                scrollbar: {
                  useShadows: false,
                  vertical: "hidden",
                  verticalScrollbarSize: 1,
                },
              }}
            />
          </div>
        </div>
        <div>
          {model && (
            <Tabs>
              <TabList>
                <Tab>APIs</Tab>
                <Tab>Test Data</Tab>
              </TabList>
              <TabPanel>
                <ApiTabPanel model={model} />
              </TabPanel>
              <TabPanel>
                <TupleEditor model={model} />
              </TabPanel>
            </Tabs>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
