export const strToTuple = (str: string) => {
  if (!str) return;
  let object_namespace, object_id, relation, userset_namespace, userset_id;
  const [object, rest] = str.split("#");
  if (object) {
    [object_namespace, object_id] = object.split(":");
    if (!object_namespace || !object_id) return;
  }

  if (!rest) {
    return {
      object_namespace,
      object_id,
    };
  }

  let parts = rest.split("@");
  if (parts.length === 2) {
    relation = parts[0];
    let userset = parts[1];
    [userset_namespace, userset_id] = userset.split(":");
  } else {
    if (parts[0].indexOf(":") > -1) {
      [userset_namespace, userset_id] = parts[0].split(":");
    } else {
      relation = parts[0];
    }
  }

  return {
    object_namespace,
    object_id,
    relation,
    userset_namespace,
    userset_id,
  };
};

export const tupleToStr = (tup: any) => {
  return `${tup.object_namespace}:${tup.object_id}#${tup.relation}@${tup.userset_namespace}:${tup.userset_id}`;
};
