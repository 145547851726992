import React, { useEffect, useRef, useState } from "react";
import { TupleEditorProps } from "./types";
import { strToTuple } from "./util";

const KEY = "anumati.tuples";

function TupleEditor({ model }: TupleEditorProps) {
  const textAreaRef = useRef<any>();
  const [loading, setLoading] = useState(false);
  const [tuples, setTuples] = useState<any[]>();

  useEffect(() => {
    fetch(`/api/stores/${model.appId}/tuples`)
      .then((res) => res.json())
      .then((data) => {
        if (!data.tuples) {
          return;
        }
        setTuples(data.tuples);
      });
  }, [model]);

  /**
   *
   * @param a - tuples
   * @param b - tuples
   * @returns - 2 sets of tuples that are added, removed
   */
  const diff = (aStr: any[], bStr: any[]) => {
    const added: any[] = [];
    const removed: any[] = [];
    aStr.forEach((t) => {
      if (!bStr.includes(t)) {
        removed.push(t);
      }
    });

    bStr.forEach((t) => {
      if (!aStr.includes(t)) {
        added.push(t);
      }
    });

    return { added, removed };
  };

  const onSave = () => {
    //@ts-ignore
    const text = textAreaRef.current.value;

    if (text) {
      setLoading(true);
      //save
      sessionStorage.setItem(KEY, text);

      const updatedTupleStrs = text.split("\n");
      const prevTupleStrs =
        tuples?.map(
          (t) =>
            `${t.objectNamespace}:${t.objectId}#${t.relation}@${t.usersetNamespace}:${t.usersetId}`
        ) || [];
      const { added, removed } = diff(prevTupleStrs, updatedTupleStrs);

      fetch(`/api/models/${model.id}/write`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          writes: added.map(strToTuple),
          deletes: removed.map(strToTuple),
        }),
      })
        .then((res) => {
          if (res.status !== 200) {
            alert("save failed ");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  // const onClear = () => {
  //   sessionStorage.removeItem(KEY);
  // };

  const tuplesText = tuples
    ? tuples
        .map(
          (t) =>
            `${t.objectNamespace}:${t.objectId}#${t.relation}@${t.usersetNamespace}:${t.usersetId}`
        )
        .join("\n")
    : "";

  return (
    <div className="App-data-editor">
      <h4>Relationship tuples</h4>
      <textarea
        className="App-data-text-area"
        ref={textAreaRef}
        // value={textFromSessionStorage}
        defaultValue={tuplesText}
        spellCheck="false"
        placeholder="folder:123#folder_admin@user:78756"
      />
      <div>
        <button className="App-data-save-btn" onClick={onSave}>
          {loading && <span>Saving...</span>}
          {!loading && <span>Save</span>}
        </button>
        {/* <button onClick={onClear}>clear</button> */}
      </div>
    </div>
  );
}
export default TupleEditor;
